<script>
import Loader from '@/components/Loader.vue';

export default {
    data() {
        return{
            loader: true,
            jobs: []
        }
    },
    created() {
        this.getJobs()
    },
    methods: {
        getJobs() {
            this.loader = true;

            this.$axios.get('jobs')
            .then(res => {
                console.log(res.data);
                this.jobs = res.data;

                this.loader = false;
            })
            .catch(err => {
                this.loader = false;
            })
        }
    },
    components: { Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section class="bg-jobs">
        <figure>
            <img src="@/assets/img/instituto-aprender-de-qualificacao-logo.png" alt="">
        </figure>

        <header>
            <h2>Vagas disponíveis</h2>

            <p @click="this.$router.push('/')">Cadastre-se ou faça login</p>
        </header>

        <section class="jobs-list">
            <div v-for="(job, index) in jobs" @click="this.$router.push(`/vagas/${job.id}`)" class="job">
                <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                    </svg>
                </figure>

                <section class="description">
                    <h2>{{ job.title }}</h2>

                    <p>{{ job.user.name }}</p>
                </section>

                <footer>
                    <p>Saiba mais</p>
                </footer>
            </div>
        </section>
    </section>
</template>

<style scoped>
.bg-jobs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 20px 20px;
}

.bg-jobs figure img {
    width: 150px;
    max-width: 98%;
    margin-top: 30px;
}

.bg-jobs header {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.bg-jobs header h2 {
    font-size: 20px;
    color: #1E2245;
    font-weight: 650;
}

.bg-jobs header p {
    font-size: 14px;
    color: #1E2245;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    transition: .2s;
}

.bg-jobs header p:hover {
    transition: .2s;
    color: #E73E19;
}

.bg-jobs .jobs-list {
    width: 100%;
    max-width: 1300px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 50px;
    gap: 20px;
}

.bg-jobs .jobs-list .job {
    width: 100%;
    border: 1px solid #1e224539;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
}

.bg-jobs .jobs-list .job figure {
    padding: 20px 20px 0px 20px;
}

.bg-jobs .jobs-list .job figure .icon {
    width: 100%;
    max-width: 30px;
    color: #1e2245c9;
}

.bg-jobs .jobs-list .job .description {
    padding: 0px 20px;
    text-align: center;
}

.bg-jobs .jobs-list .job .description h2 {
    font-size: 16px;
    font-weight: 600;
    color: #1e2245da;
    border-bottom: 1px solid #1e224539;
    padding-bottom: 10px;
}

.bg-jobs .jobs-list .job .description p {
    font-size: 12px;
    font-weight: 500;
    color: #1e2245b6;
    padding-top: 10px;
}

.bg-jobs .jobs-list footer {
    width: 100%;
    text-align: center;
    background-color: #1e2245;
    padding: 5px 0px;
    color: #FFF;
    border-radius: 0px 0px 10px 10px;
}

@media screen and (max-width:870px) {
    .bg-jobs .jobs-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:660px) {
    .bg-jobs .jobs-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:490px) {
    .bg-jobs .jobs-list {
        grid-template-columns: 1fr;
    }
}
</style>