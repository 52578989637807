<script>
import Loader from '@/components/Loader.vue';

export default {
    data() {
        return{
            loader: true,
            job: []
        }
    },
    created() {
        this.getJobs();
    },
    methods: {
        getJobs() {
            this.loader = true;

            this.$axios.get(`jobs/${this.$route.params.id}`)
            .then(res => {
                console.log(res.data);
                this.job = res.data;

                this.loader = false;
            })
            .catch(err => {
                this.$router.push('/vagas');
            })
        }
    },
    components: { Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section v-if="!loader" class="bg-jobs">
        <figure>
            <img src="@/assets/img/instituto-aprender-de-qualificacao-logo.png" alt="">
        </figure>

        <header>
            <h2>Vaga Aberta!</h2>

            <p @click="this.$router.push('/vagas')">Ver todas as vagas</p>
        </header>

        <section class="jobs-infos">
            <section class="job-inf">
                <p>Título da vaga: <span>{{ job.title }}</span></p>
                <p>Empresa: <span>{{ job.user.name }}</span></p>
            </section>

            <section class="job-description">
                <p class="description-title">Descrição:</p>
                <p>{{ job.description }}</p>
            </section>

            <section class="job-contact">
                <p class="contact-title">Contato:</p>
                <p>{{ job.contact }}</p>
            </section>
        </section>
    </section>
</template>

<style scoped>
.jobs-infos {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
    max-width: 1300px;
}

.jobs-infos .job-inf {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.jobs-infos .job-inf p {
    font-size: 18px;
    font-weight: 500;
    color: #1E2245;
}

.jobs-infos .job-inf span {
    font-size: 16px;
    font-weight: 400;
    color: #1E2245;
}

.jobs-infos .job-description .description-title,
.jobs-infos .job-contact .contact-title {
    font-size: 18px;
    font-weight: 500;
    color: #1E2245;
}

.jobs-infos .job-contact p,
.jobs-infos .job-contact p {
    font-size: 16px;
    font-weight: 400;
    color: #1E2245;
}

.bg-jobs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 20px 20px;
}

.bg-jobs figure img {
    width: 150px;
    max-width: 98%;
    margin-top: 30px;
}

.bg-jobs header {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.bg-jobs header h2 {
    font-size: 20px;
    color: #1E2245;
    font-weight: 650;
}

.bg-jobs header p {
    font-size: 14px;
    color: #1E2245;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    transition: .2s;
}

.bg-jobs header p:hover {
    transition: .2s;
    color: #E73E19;
}

.bg-jobs .jobs-list {
    width: 100%;
    max-width: 1300px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 50px;
    gap: 20px;
}

.bg-jobs .jobs-list .job {
    width: 100%;
    border: 1px solid #1e224539;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
}

.bg-jobs .jobs-list .job figure {
    padding: 20px 20px 0px 20px;
}

.bg-jobs .jobs-list .job figure .icon {
    width: 100%;
    max-width: 30px;
    color: #1e2245c9;
}

.bg-jobs .jobs-list .job .description {
    padding: 0px 20px;
    text-align: center;
}

.bg-jobs .jobs-list .job .description h2 {
    font-size: 16px;
    font-weight: 600;
    color: #1e2245da;
    border-bottom: 1px solid #1e224539;
    padding-bottom: 10px;
}

.bg-jobs .jobs-list .job .description p {
    font-size: 12px;
    font-weight: 500;
    color: #1e2245b6;
    padding-top: 10px;
}

.bg-jobs .jobs-list footer {
    width: 100%;
    text-align: center;
    background-color: #1e2245;
    padding: 5px 0px;
    color: #FFF;
    border-radius: 0px 0px 10px 10px;
}

@media screen and (max-width:870px) {
    .bg-jobs .jobs-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:660px) {
    .bg-jobs .jobs-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:490px) {
    .bg-jobs .jobs-list {
        grid-template-columns: 1fr;
    }
}
</style>