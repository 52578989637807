<script>
import Input from '@/components/Input.vue'
import Head from './../../components/Head.vue'
import SectionTitle from './../../components/SectionTitle.vue'
import Button from '@/components/Button.vue'
import Section from '@/components/Section.vue'
import Table from './../../components/Table.vue'
import Select from '@/components/Select.vue'
import ReturnToPage from '@/components/ReturnToPage.vue'
import Loader from './../../components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import DeleteModal from '@/components/DeleteModal.vue'

export default {
    props: [ 'userData' ],
    data() {
        return {
            loader: true,
            view: 'list-cbos',
            hasNextPage: undefined,
            itemsPerPage: 10,
            actualPage: 0,
            totalItems: 0,
            totalPages: 0,
            searchTermsFilter: '',
            tableHeaders: [
                'ID',
                'Título',
                'Empresa',
                'Descrição',
            ],
            tableActions: [
                'trash'
            ],
            tableContents: [],
            tableContentsFullInfos: [],
            tableContentsFullInfosSelectedUser: [],
            editCBO: {
                company_id: 0,
                title: '',
                description: '',
                contact: '',
            },
            statusCompaniesSelect: [],
            companySelect: [],
            selectedDeleteId: 0,
            deleteApprenticeModal: false,
        }
    },
    created() {
        this.getNextPageApprentices();
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        getNextPageApprentices() {
            this.loader = true;

            this.actualPage += 1;

            this.$axios.get(`/jobs_admin?items_per_page=${this.itemsPerPage}&page=${this.actualPage}&terms_filter=${this.searchTermsFilter}`)
            .then(res => {
                console.log(res);

                const data = res.data.data.data;

                this.tableContentsFullInfos = data;

                var arr = [];

                data.forEach(element => {
                    arr.push({
                        id: element.id,
                        title: element.title,
                        company: element.user.name.slice(0, 65) + '...',
                        description: element.description.slice(0, 65) + '...',
                    });
                });

                this.tableContents = arr;

                res.data.data.last_page > 1 ? this.hasNextPage = true : this.hasNextPage = false;

                this.totalItems = res.data.data.total;
                this.totalPages = res.data.data.last_page;

                this.loader = false;
            })
            .catch(err => {
                console.log(err);
            });
        },
        listApprenticesActions(event) {
            if (event.eventType == 'trash') {
                for (let i = 0; i < this.tableContentsFullInfos.length; i++) {
                    if (this.tableContentsFullInfos[i].id === event.data.id) {
                        this.selectedDeleteId = this.tableContentsFullInfos[i].id;
                    }
                }

                this.deleteApprenticeModal = true;
            }
        },
        modalDeleteClickedButton(event) {
            console.log(event);
            if (!event) {
                this.selectedDeleteId = null;
                this.deleteApprenticeModal = false;
            }

            if (event) {
                this.loader = true;

                this.$axios.delete(`/jobs/${this.selectedDeleteId}`)
                .then(res => {
                    this.notify('Vaga removida com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch(err => {
                    this.notify('Ocorreu um erro e não foi possível remover essa vaga!', 'error');
                });
            }
        },
        actionPaginationArrows(event) {
            console.log(event);
            if (event == 'previous') {
                this.actualPage -= 2;

                this.getNextPageApprentices();
            };

            if (event == 'next') {
                this.getNextPageApprentices();
            };
        },
        actionPaginationPages(event) {
            this.actualPage = event - 1;

            this.getNextPageApprentices();
        },
        getApprenticesFilter() {
            this.actualPage = 0;

            this.getNextPageApprentices();
        },
        editYoungApprenticies() {
            if (this.view == 'add-cbos') {
                console.log(this.editCBO);
                if (!this.editCBO.company_id) {
                    return this.notify('Selecione uma empresa', 'error');
                }

                if (!this.editCBO.title) {
                    return this.notify('Digite um título', 'error');
                }

                if (!this.editCBO.description) {
                    return this.notify('Digite uma descrição', 'error');
                }

                if (!this.editCBO.contact) {
                    return this.notify('Digite um contato', 'error');
                }

                this.loader = true;

                this.$axios.post('jobs', this.editCBO)
                .then(res => {
                    this.notify('Vaga criada com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a criação da vaga!', 'error');
                    this.loader = false;
                });
            }
        },
        addJob() {
            this.loader = true;
            
            this.$axios
            .get("contracts/data/to_contract")
            .then((res) => {
                const data = res.data.data;
                console.log(data);

                this.editCBO.company_id = data.companies[0].user_id;

                data.companies.forEach((element) => {
                    this.companySelect.push({
                        name: element.social_reason_company,
                        value: element.user_id,
                    });
                });

                this.view = "add-cbos";
                this.loader = false;
            })
            .catch((err) => {
                this.notify(
                    "Ocorreu um erro ao tentar abrir a tela de cadastro de contratos!",
                    "error"
                );
                this.loader = false;
            });
        },
    },
    components: { Head, SectionTitle, Input, Button, Section, Table, Select, ReturnToPage, Loader, DeleteModal }
}
</script>

<template>
    <Loader v-if="loader" />

    <DeleteModal v-if="deleteApprenticeModal" @clickButton="modalDeleteClickedButton" title="Deletar aprendiz" />

    <section v-if="!loader">
        <Head :title="view == 'list-cbos' ? 'Vagas' : view == 'edit-cbos' ? 'Editar Vagas' : 'Adicionar Vagas'" />

        <!-- Adicionar ou editar Vaga -->
        <Section v-if="view == 'edit-cbos' || view == 'add-cbos'">
            <ReturnToPage placeholder="Voltar para a listagem de vagas" redirectTo="" @clickReturn="view = 'list-cbos'"></ReturnToPage>

            <div class="form-divider"></div>

            <Select
                label="Selecione a empresa"
                :options="companySelect"
                :value="editCBO.company_id"
                v-model="editCBO.company_id"
            />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Título da vaga" label="Digite o título da vaga" :value="editCBO.title" v-model="editCBO.title" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Descrição da vaga" label="Descreva a vaga" :value="editCBO.description" v-model="editCBO.description" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Contato da vaga" label="E-mail, Telofone, Site..." :value="editCBO.contact" v-model="editCBO.contact" />

            <div class="form-divider"></div>

            <Button type="primary" :placeholder="view == 'edit-cbos' ? `Editar Vaga` : 'Adicionar Vaga'" @buttonPressed="editYoungApprenticies" />
        </Section>

        <!-- Listar Vagas -->
        <Section v-if="view == 'list-cbos'">
            <SectionTitle title="Gerenciar Vagas" />

            <div class="form-divider"></div>

            <section class="top-add-button">
                <Button @buttonPressed="addJob" class="add-button" type="primary" placeholder="+ Adicionar Vaga" />
            </section>

            <div class="form-divider"></div>

            <Table v-if="!loader" :hasActions="true" :actions="tableActions" :hasNextPage="hasNextPage" :totalItems="totalItems" :totalPages="totalPages" :actualPage="actualPage" :headers="tableHeaders" :contents="tableContents" @loadMore="getNextPage" @clickAction="listApprenticesActions" @paginationArrows="actionPaginationArrows" @paginationPages="actionPaginationPages" />
        </Section>
    </section>
</template>

<style scoped>
.top-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -55px;
}

.add-button {
    width: 300px;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .top-add-button {
        margin-top: 0px;
    }

    .add-button {
        width: 100%;
        justify-content: center;
    }
}
</style>